<template>
  <div class="wrapper">
    <div class="cart-items">
      <div v-if="showCart">
        <div class="item" v-for="item in cart" :key="item.id">
          <div class="item-info">
            <h3>{{item.name}}</h3>
            <p>Unit Price: {{item.price}}</p>
            <p>Quantity: {{item.quantity}}</p>
          </div>
          <div class="button">
              <fieldset id="fieldset" class="fieldset">
                <legend>Remove from Cart:</legend>
                <select :id="item.id">
                  <option v-for="num in item.quantity" :value="num" :key="num">{{num}}</option>
                </select>
                <button class="auto" v-on:click="removeFromCart(item.id)">Remove</button>
              </fieldset>
          </div>
        </div>
      </div>
      <div v-else>
        <h2>Your Cart is Empty!</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CartList',
  props: {
    cart: Array
  },
  methods: {
    removeFromCart(itemID) {
      let selector = document.getElementById(itemID);
      let val = selector.options[selector.selectedIndex].value;
      for (var key in this.$root.$data.cart) {
        if (this.$root.$data.cart[key].id == itemID) {
          this.$root.$data.cart[key].quantity -= val;
          if (this.$root.$data.cart[key].quantity == 0) {
            this.$root.$data.cart.splice(key, 1);
          }
        }
      }
    },
  },
  computed: {
    showCart() {
      if (this.$root.$data.cart.length < 1) {
        return false;
      }
      return true;
    }
  }
}
</script>

<style scoped>

.item {
  margin-bottom: 10px;
  border: 1px solid black;
}
.item-info {
  margin: 10px;
}
h3 {
  margin: 0;
  padding: 10px;
  color: #eee;
  background-color: #F2921D;
}
button {
  color: #eee;
  background-color: #F2921D;
  border: none;
  padding: 5px 8px;
}
select {
  margin-right: 10px;
}
fieldset {
  margin: 10px;
}
</style>
